
<template>
    <Layout>
        <PageHeader :title="title" :items="[]" />
        <LoadingVue v-if="load" />
    
        <div class="row g-2" v-else>
            <div class="col-md-8 mx-auto">
                  <Notification />
    <div class="row">
    
                <div class="col-md-4">
                    <div>
    
                        <div class="row">
                          <div class="col-12" v-for="(item, index) in items" :key="item.id">
    
    
    
                                    <div v-if="index  <  itemlist" class="card" :class="{active: addclass(item)}" @click="selectedelct(item)">
    
                                        <div class="card-body p-1">
                                            <div class="form-check custom-option custom-option-basic checked">
                                                <label class="form-check-label custom-option-content" :for="item.code">
                      
                        <img :src="link+item.image" alt="" class="rounded-circle avatar-sm  me-2">
                        <span class="custom-option-body">
                          <strong class="fs-5">{{item.name}}</strong>
                        </span>
                      </label>
                                            </div>
                                        </div>
                                    </div>
    
                                </div>
                                <div v-if="(items.length > itemlist) || (more == true)" @click="viewmore" class="d-flex justify-content-center mb-3">
                                    <b-button variant="primary" size="sm">{{more ? "less": "more"}}</b-button>
                                </div>
                        </div>
    
    
                        <!--end row-->
    
                        <!--end offcanvas-->
                    </div>
                </div>

                
                <div class="col-md-8">
     <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Betting</h4>
           
          </div>
          <!-- end card header -->
          <div class="card-body">
            
            <div class="live-preview">
              <form action="javascript:void(0);">
                <div class="mb-3">
                  <label for="employeeName" class="form-label"
                    >Account Id</label
                  >
                  <input
                    type="text"
                    v-model="account"
                    class="form-control"
                    id="employeeName"
                    placeholder="Account"
                  />
                </div>
                <div class="mb-3" v-if="verify && name">
                  <label for="employeeUrl" class="form-label"
                    >Name</label
                  >
                  <input
                    type="url"
                    class="form-control"
                    readonly
                    v-model="name"
                    id="employeeUrl"
                    placeholder="Name"
                  />
                </div>

                <div class="mb-3" v-if="verify && name">
                  <label for="employeeUrl" class="form-label"
                    >Amount You wish to fund</label
                  >
                  <input
                    type="url"
                    class="form-control"
               
                    v-model="amount"
                    id="employeeUrl"
                    placeholder="Amount"
                  />
                </div>
               
                <div class="text-end">
                  <button type="submit" :disabled="subload" @click="submit" class="btn btn-primary">
                   <div class="spinner-border spinner-border-sm" role="status" v-if="subload" > 
  <span class="sr-only">Loading...</span>
</div>

                Continue
                  </button>
                </div>
              </form>
            </div>
          
          </div>
        </div>
      </div>
   
    </div>
                </div>
                </div>
            </div>
            <!-- end col -->
        </div>
        <!--end row-->
    
    
    </Layout>
</template>
<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import axios from 'axios';
import Swal from "sweetalert2";
import LoadingVue from "./components/Loading.vue";
export default {
    page: {
        title: "Betting",
        meta: [{ name: "description", content: appConfig.description }],
    },
    data() {
        return {
            link: process.env.VUE_APP_URL,
            title: "Betting",
            itemlist: 3,
            load: false,
            subload: false,
            account: null,
            amount: null,
            name: null,
            verify: false,
            selecteditem: null,
            items: [],
            more: false,
        };
    },
    name: "Widgets",
    components: {
        Layout,
        PageHeader,
        LoadingVue,
    },
    created() {
        this.getitems();
    },
    watch: {
      selecteditem: function() {

    this.verify = false;
        this.name = null;

      },
      account: function() {
        this.verify = false;
        this.name = null;


        
      },
     
    },

    methods: {


       submit(){


    if(this.verify == true){

 this.processtransaction();

    }else {

    this.verifyaccount();


    }
},
        async processtransaction() {
       this.subload = true;

          await axios.post(process.env.VUE_APP_MAIN_URL + "/process/betting", { type: this.selecteditem.code, account: this.account,  amount: this.amount }).catch((error) => {

              this.subload = false;

            if (error.response.status == 420) {
               Swal.fire("Error!", error.response.data.data, "error");
            }else {
                  Swal.fire("Error!", "Please make sure you have active internet or fields are correct", "error");
            }

               
                throw error;

            }).then((response) => {
                this.subload = false;
                 this.$router.push({name: "transaction", params: { hash: response.data.data.data.hash}});

            })

      },



    async verifyaccount() {
       this.subload = true;

     
            await axios.post(process.env.VUE_APP_MAIN_URL + "/verify/betting", { type: this.selecteditem.code, account: this.account, }).catch((error) => {
                if (error.response.status == 420) {
                    Swal.fire("Error!", error.response.data.data, "error");
                    // console.log(error.response.data)
                } else {
                    Swal.fire("Error!", "Something Went Wrong", "error");
                }

                this.subload = false;
                throw error;

            }).then((response) => {

                if (response.data.status == true) {
                    console.log(response.data.data.plans);

                    this.name = response.data.data.name;
                 
                    this.verify = true;


                }
                this.subload = false;

            })


               this.subload = false;
    },
    
    
    
    





        viewmore() {

            if (this.more == false) {
                this.more = true;
                this.itemlist = 100

            } else {

                this.more = false;
                this.itemlist = 3

            }





        },
            selectedelct(item) {
            this.selecteditem = item;
        },
        addclass(item) {


            return item == this.selecteditem;
        },
           async getitems() {
               this.load = true;
            await axios.get(process.env.VUE_APP_MAIN_URL + "/get/bettings").catch((error) => {

this.load = false;
                Swal.fire("Error!", "Please Check Your Internet Connection", "error");
                throw error;

            }).then((response) => {
this.load = false;
                if (response.data.status == true) {

                    this.items = response.data.data.data;


                }

            })
        },
    },
};
</script>
<style scoped>
.active {
    border: 2px solid green;
}
</style>